import { Page, Section, Button } from "@/components";
import Link from "next/link";

export default function Custom404() {
  return (
    <Page>
      <Section wrapperClassName="flex-1 flex items-center justify-center text-center py-16">
        {/* <h1 className="h1">404</h1> */}
        <h2 className="h2 text-primary">404 Page Not Found</h2>
        <p className="text-lg mt-4 mb-8">
          The page you are looking for does not exist. Please check the URL and try again.
        </p>
        <Button asChild>
          <Link href="/">Go to Homepage</Link>
        </Button>
      </Section>
    </Page>
  );
}
